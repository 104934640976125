






import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

// components
import FolderForm from '@/components/forms/depository/FolderForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component({
  components: {
    FolderForm,
  },
})
export default class DepositoryFilesFolderNew extends Mixins(NotifyMixin, PermissionsMixin) {
  private metaInfo (): MetaInfo {
    return {
      title: 'Новая папка',
    }
  }
}
