





























import { isEqual } from 'lodash'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// component
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { LargeKnowledgeResource, MediaResource } from '@/store/types'
// import ManagerDepositoryModule from '@/store/modules/manager/depository'

interface IFolderForm {
  folder: string,
  mediaIds: number[],
  parentId: number,
}

@Component({
  components: {
    AttachmentsWrapper,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FolderForm extends Mixins(NotifyMixin) {
  @Prop({ default: null })
  private folder!: LargeKnowledgeResource

  @Prop({ default: false })
  private readonly !: boolean

  private get isSystem(): boolean {
    return Boolean(ManagerDepositoryModule.foldersTree.folders.find(folder => folder.id === this.folderID)?.isSystem)
  }

  private get courseID(): number {
    return +this.$route.params.courseID
  }

  private get monthID(): number {
    return +this.$route.params.monthID
  }

  private get folderID(): number {
    return +this.$route.params.folderID
  }

  private get parentID(): number {
    return +this.$route.query.parentID
  }

  private isReady = false
  private form: IFolderForm = {
    folder: '',
    mediaIds: [],
    parentId: this.parentID,
  }
  private files: MediaResource[] = []

  private mounted () {
    this.files = this.folder ? this.folder.media : []
    if (this.folder) {
      this.syncForm(this.folder)
    } else {
      this.isReady = true
    }
  }

  private syncForm (value: any) {
    this.form = {
      folder: value.folder,
      mediaIds: value.media.map((file: MediaResource) => file.id),
      parentId: +this.parentID,
    }
    this.isReady = true
  }

  private handleSubmit() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.folder) {
            // Редактирование
            const body: any = {
              folder: this.form.folder,
              media: this.form.mediaIds,
            }
            if (this.parentID) {
              body.parentId = this.form.parentId
            }
            ManagerDepositoryModule.updateFolder({
              body,
              courseID: this.courseID,
              courseFolderID: this.monthID,
              mediaFolderID: this.folder.id,
            })
              .then(() => {
                this.notifySuccess('Папка обновлена.')
                this.$bus.$emit('updateTree')
                this.$router.push({
                  name: 'manager.bank.depository.courses.item.files.month',
                  params: {
                    courseID: this.$route.params.courseID,
                    monthID: this.$route.params.monthID,
                  },
                })
              })
              .catch(this.notifyError)
          } else {
            // Создание
            const body: any = {
              courseFolderId: this.monthID,
              folder: this.form.folder,
              media: this.form.mediaIds,
            }
            if (this.parentID) {
              body.parentId = this.parentID
            }
            ManagerDepositoryModule.createFolder({ body, courseID: this.courseID, courseFolderID: this.monthID })
              .then(() => {
                this.notifySuccess('Папка успешно создана.')
                this.$bus.$emit('updateTree')
                this.$router.push({
                  name: 'manager.bank.depository.courses.item.files.month',
                  params: {
                    courseID: this.$route.params.courseID,
                    monthID: this.$route.params.monthID,
                  },
                })
              })
              .catch(this.notifyError)
          }
        }else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Watch('folder')
  private watchfolder (value: any, oldValue: any) {
    if (!isEqual(value, oldValue)) {
      this.syncForm(value)
    }
  }
}
